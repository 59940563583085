// src/components/CheckoutForm.js
import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import stripeLogo from './stripe-logo.png'; // Ensure this path is correct

const CheckoutForm = ({ totalAmount, cartItems, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name || !email || !checkInDate) {
      setError('Name, Email, and Check-in Date are required.');
      return;
    }

    // Validate the check-in date
    const today = new Date();
    const minCheckInDate = new Date(today.setDate(today.getDate() + 1));
    if (new Date(checkInDate) < minCheckInDate) {
      setError('Check-in Date must be at least one day from today.');
      return;
    }

    setLoading(true);

    try {
      const { data: { clientSecret, orderId } } = await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/create-payment-intent`, {
        amount: totalAmount * 100, // Stripe expects the amount in cents
        name,
        email,
        checkInDate,
        products: cartItems.map(item => ({
          name: item.name,
          quantity: item.quantity,
          price: item.price
        }))
      });

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
            email,
          },
        },
      });

      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
      } else {
        setError(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/api/payment/confirm-payment`, {
          paymentIntentId: payload.paymentIntent.id,
          orderId
        });

        // Call onPaymentSuccess with orderId to fetch order details and generate the receipt
        onPaymentSuccess(orderId);
      }
    } catch (error) {
      setError(`Payment failed: ${error.message}`);
    }

    setLoading(false);
  };

  const cardElementOptions = {
    hidePostalCode: true
  };

  // Calculate the minimum check-in date (one days from today)
  const getMinCheckInDate = () => {
    const today = new Date();
    const minCheckInDate = new Date(today.setDate(today.getDate() + 1));
    return minCheckInDate.toISOString().split('T')[0];
  };

  return (
    <div className="card mt-3">
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="checkInDate">Check-in Date</label>
            <input
              type="date"
              className="form-control"
              id="checkInDate"
              value={checkInDate}
              onChange={(e) => setCheckInDate(e.target.value)}
              min={getMinCheckInDate()}
              required
            />
          </div>
          <p></p>
          <p className="card-text">Your payment is secure and powered by Stripe.</p>
          <img src={stripeLogo} alt="Stripe Logo" className="mb-3" style={{ width: '60px' }} />
          <div className="form-group mb-3">
            <CardElement className="form-control" options={cardElementOptions} />
          </div>
          <button type="submit" className="btn btn-primary" disabled={!stripe || loading}>
            {loading ? 'Processing...' : `Pay €${totalAmount.toFixed(2)}`}
          </button>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;
