import React, { useState, useRef, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import './Cart.css'; // Import the new CSS file for mobile styles

const stripePromise = loadStripe('pk_live_51PdHPpKLlJ3iMAGopdoLzmcsvx8qAc9FQx2Qg50Hf9HPTmq3Y6cbNOLNyX5j6N0LNCmdjwpX6GnwfUI9oN2v1mXq00KFlfzHGj');

function Cart({ cartItems, onRemoveFromCart, clearCart }) {
  const [showCheckout, setShowCheckout] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isMobile, setIsMobile] = useState(false); // State to track if the device is mobile
  const [showCartDetails, setShowCartDetails] = useState(false); // State to toggle cart details on mobile
  const checkoutFormRef = useRef(null); // Reference to the checkout form

  useEffect(() => {
    // Function to handle screen resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Scroll to the checkout form when it becomes visible
    if (showCheckout && checkoutFormRef.current) {
      checkoutFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showCheckout]);

  const getTotal = () => {
    return cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2);
  };

  const handlePaymentSuccess = async (orderId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/payment/orders/${orderId}`);
      setOrderDetails(response.data);
      generatePDFReceipt(response.data);
      clearCart(); // Clear the cart after receipt is generated
      setShowCheckout(false); // Hide checkout form
    } catch (error) {
      console.error('Failed to fetch order details:', error);
    }
  };

  const generatePDFReceipt = (order) => {
    const doc = new jsPDF();
    doc.setFontSize(20);
    doc.text('Order Receipt', 20, 20);
    doc.setFontSize(12);
    doc.text(`Order ID: ${order._id}`, 20, 30);
    doc.text(`Order Date: ${new Date(order.createdAt).toLocaleDateString()}`, 20, 40);
    doc.text(`Name: ${order.name}`, 20, 50);
    doc.text(`Email: ${order.email}`, 20, 60);
    doc.text(`Check-in Date: ${new Date(order.checkInDate).toLocaleDateString()}`, 20, 70);

    const tableColumn = ["Item", "Quantity", "Price"];
    const tableRows = [];

    order.products.forEach(product => {
      const productData = [
        product.name,
        product.quantity,
        `€${(product.price * product.quantity).toFixed(2)}`
      ];
      tableRows.push(productData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 80 });
    doc.text(`Total: €${order.totalAmount.toFixed(2)}`, 20, doc.autoTable.previous.finalY + 10);

    doc.save(`receipt_${order._id}.pdf`);
  };

  if (isMobile) {
    return (
      <div className="mobile-cart-container">
        <div className="mobile-cart-header">
          <div>Total: €{getTotal()}</div>
          <button onClick={() => setShowCartDetails(!showCartDetails)}>
            {showCartDetails ? 'Hide Cart' : 'View Cart'}
          </button>
        </div>
        {showCartDetails && (
          <div className="mobile-cart-details">
            <div className="mobile-cart-scroll">
              <ul className="list-group mb-3">
                {cartItems.map((item, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    {item.name} x{item.quantity}
                    <span className="badge badge-primary badge-pill">€{(item.price * item.quantity).toFixed(2)}</span>
                    <button className="btn btn-danger btn-sm" onClick={() => onRemoveFromCart(item._id)}>Remove</button>
                  </li>
                ))}
              </ul>
              <button className="btn btn-primary" onClick={() => setShowCheckout(true)}>Checkout</button>
              {showCheckout && (
                <div className="mt-3" ref={checkoutFormRef}>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm totalAmount={parseFloat(getTotal())} cartItems={cartItems} onPaymentSuccess={handlePaymentSuccess} />
                  </Elements>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="container">
      <h2>Cart</h2>
      <ul className="list-group mb-3">
        {cartItems.map((item, index) => (
          <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            {item.name} x{item.quantity}
            <span className="badge badge-primary badge-pill">€{(item.price * item.quantity).toFixed(2)}</span>
            <button className="btn btn-danger btn-sm" onClick={() => onRemoveFromCart(item._id)}>Remove</button>
          </li>
        ))}
      </ul>
      <h3>Total: €{getTotal()}</h3>
      <button className="btn btn-primary" onClick={() => setShowCheckout(true)}>Checkout</button>
      {showCheckout && (
        <div className="mt-3" ref={checkoutFormRef}>
          <Elements stripe={stripePromise}>
            <CheckoutForm totalAmount={parseFloat(getTotal())} cartItems={cartItems} onPaymentSuccess={handlePaymentSuccess} />
          </Elements>
        </div>
      )}
      {orderDetails && (
        <div className="mt-3">
          <h3>Order Details</h3>
          <p>Order ID: {orderDetails._id}</p>
          <p>Order Date: {new Date(orderDetails.createdAt).toLocaleDateString()}</p>
          <p>Name: {orderDetails.name}</p>
          <p>Email: {orderDetails.email}</p>
          <p>Check-in Date: {new Date(orderDetails.checkInDate).toLocaleDateString()}</p>
          <ul>
            {orderDetails.products.map(product => (
              <li key={product._id}>{product.name} - {product.quantity} x €{product.price.toFixed(2)}</li>
            ))}
          </ul>
          <p>Total: €{orderDetails.totalAmount.toFixed(2)}</p>
        </div>
      )}
    </div>
  );
}

export default Cart;
