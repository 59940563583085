// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProductList from './components/ProductList';
import Cart from './components/Cart';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo1.png'; // Adjust the path if needed

function App() {
  const [cartItems, setCartItems] = useState([]);
  const [quantities, setQuantities] = useState({});

  const handleAddToCart = (product, quantity) => {
    if (quantity > 0) {
      setCartItems(prevItems => {
        const existingItem = prevItems.find(item => item._id === product._id);
        if (existingItem) {
          return prevItems.map(item =>
            item._id === product._id ? { ...item, quantity: quantity } : item
          );
        } else {
          return [...prevItems, { ...product, quantity }];
        }
      });
      setQuantities(prevQuantities => ({ ...prevQuantities, [product._id]: quantity }));
    } else {
      handleRemoveFromCart(product._id);
    }
  };

  const handleRemoveFromCart = (productId) => {
    setCartItems(prevItems => prevItems.filter(item => item._id !== productId));
    setQuantities(prevQuantities => ({ ...prevQuantities, [productId]: 0 }));
  };

  const clearCart = () => {
    setCartItems([]);
    setQuantities({});
  };

  return (
    <Router>
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand d-flex align-items-center" href="https://www.grocerease.es">
            <img src={logo} alt="Grocerease Logo" style={{ width: '100px', marginRight: '10px', marginLeft: '10px' }} />
            Grocerease
          </Link>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Shop</Link>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container">
          <div className="row">
            {/*<div className="col-md-9">*/}
            <div className="col-lg-8 col-md-12">
              <Routes>
                <Route path="/" element={
                  <React.Fragment>
                    <ProductList onAddToCart={handleAddToCart} quantities={quantities} />
                  </React.Fragment>
                } />
              </Routes>
            </div>
            {/*<div className="col-md-3">*/}
            <div className="col-lg-4 col-md-12">
              <div className="sticky-top">
                <Cart cartItems={cartItems} onRemoveFromCart={handleRemoveFromCart} clearCart={clearCart} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;